<template>
  <nesi-user-common :title="`人工核验`">
    <template #content>
      <div class="container">
        <div class="breadcrumb" v-if="type && type === '1'">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/houseManage/list' }"
              >个人中心</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/houseManage/list' }"
              >房源管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>房源发布</el-breadcrumb-item>
            <el-breadcrumb-item>补充材料</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="breadcrumb" v-else>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/houseManage/list' }"
              >个人中心</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/houseManage/list' }"
              >房源管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>房源发布</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div
          class="step-wrapper"
          finish-status="success"
          v-if="type && type === '1'"
        >
          <el-steps :active="1" align-center>
            <el-step title="补充房屋信息"></el-step>
            <el-step title="重新申请结果"></el-step>
            <el-step title="等待审核"></el-step>
          </el-steps>
        </div>
        <div class="step-wrapper" finish-status="success" v-else>
          <el-steps :active="3" align-center>
            <el-step title="查询权属信息"></el-step>
            <el-step title="填写房屋信息"></el-step>
            <el-step title="发布申请结果"></el-step>
          </el-steps>
        </div>
        <div class="success-wrapper">
          <div>
            <i
              :class="icon"
              style="font-size: 50px;"
              :style="{ color: color }"
            ></i>
          </div>
          <div class="text">{{ resultText }}</div>
          <div class="tips">{{ tips }}</div>
          <div class="btn-wrapper">
            <el-button type="primary" plain @click="goHome" size="small"
              >返回首页</el-button
            >
            <el-button
              type="primary"
              plain
              style="margin-left: 30px;"
              size="small"
              @click="toList"
              >房源管理</el-button
            >
          </div>
        </div>
      </div>
    </template>
  </nesi-user-common>
</template>

<script>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  components: { NesiUserCommon },
  name: 'success-wrapper',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const icon = ref('el-icon-success')
    const color = ref('#19be6b')
    const resultText = ref('操作成功')
    // const tips = ref('已提交监管系统审核(1-3个工作日)，请耐心等待审核结果')
    const tips = ref('')
    const status = ref(route.params.status)
    const message = ref(route.params.message)
    const type = ref(route.params.type)

    if (status.value === 'fail') {
      icon.value = 'el-icon-error'
      color.value = '#fa3534'
      resultText.value = '操作失败'
      tips.value = '失败原因：' + message.value
    }

    const toList = () => {
      router.push('/user/house/index')
    }

    const goHome = () => {
      router.push('/')
    }

    return {
      status,
      message,
      icon,
      color,
      resultText,
      tips,
      toList,
      type,
      goHome
    }
  }
})
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  // box-shadow: 0 0 50px 5px #e4e7ed;
  .step-wrapper {
    padding: 30px 0;
  }

  .success-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;

    .text {
      padding: 10px 0;
      font-size: 18px;
    }

    .tips {
      font-size: 13px;
      color: #909399;
      margin-bottom: 30px;
    }

    .btn-wrapper {
    }
  }
}
.breadcrumb {
  padding: 0 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid #e4e7ed;
}
</style>
